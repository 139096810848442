@import 'vars';

body {
    font-family: $font;
    font-size: 24px;
    line-height: 1.2;
    color: $txt;
    font-weight: normal;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $primary;
    margin: 1em 0;
    padding: 0;
}

h1, h2, h3, h4 {
    color: $primary;
    font-weight: 600;
}
h1, h2 {
    font-size: 1.6em;
}
h4 {
    font-weight: bold;
}
table {
    th, td {
        padding: 10px 20px;
    }
    th {
        color: $shade;
        text-align: left;
        vertical-align: top;
        padding-left: 0px;
    }
}

.clear-float {
    clear:both;
}

.intro {
    color: $primary;
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 0px;
}

.button {
   @include button;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
ul {
    overflow:hidden;    /* prevents bullets overlapping floated images */
}
ul.tick {
    list-style-type: none;
    padding: 0px;
    margin: 2em 0px;
    margin-top: 2.5em;
    li {
        position: relative;
        padding: 0px;
        padding-left: 40px;
        margin: 7px 0px;
        &:before {
            content:"";
            position: absolute;
            top: 4px;
            left: 0px;
            display: inline-block;
            width: 25px;
            height: 20px;
            background-image: url('../assets/tick.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left top;
        }
    }
}

.excerpt { /* pull-out text style */
    color: $primary;
    padding: 30px 2%;
    box-sizing: border-box;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    margin: 2em 0px;
    p {
        font-weight: 300;
        font-style: italics;
        font-size: 1.6em;
        line-height: 1.12;
    }
    h4 {
        font-size: 1rem;
        margin-top: 2em;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
@media only screen and (max-width:500px){
    body {
        font-size: 18px;
    }
    h1, h2 { /* back to browser default */
        font-size: 1.5em;
        line-height: 1;
    }
}
